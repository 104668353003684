import { PdfViewer } from '@blissbook/ui/lib/pdf'
import React from 'react'
import ReactDOM from 'react-dom'

const pdfEls = document.getElementsByTagName('rw-pdf')
for (const pdfEl of pdfEls) {
  const thumbnailUrl = pdfEl.hasAttribute('data-thumbnail-url')
    ? pdfEl.getAttribute('data-thumbnail-url')
    : undefined
  const props = {
    height: Number.parseInt(pdfEl.style.height, 10),
    thumbnailUrl,
    url: pdfEl.getAttribute('src'),
    width: Number.parseInt(pdfEl.style.width, 10),
  }

  ReactDOM.render(<PdfViewer {...props} />, pdfEl)
}
